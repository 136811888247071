<template>
    <div ref="tier" class="content-index-tier">
        <div v-if="title && !hideEmptyResultsTitle" class="heading">
            <div class="heading-wrapper">
                <Typography as="h2" variant="h3-display" class="title">{{ title }}</Typography>
            </div>
        </div>
                <div class="panel">
                    <div class="panel-inner">
                        <div v-if="hasSearch" class="search">
                            <form @submit.prevent="onSubmit" class="search-input">
                                <TextInput
                                    v-model:inputValue.trim="searchString"
                                    :isClearable="meiliSearchQuery == searchString && searchString.length > 0"
                                    icon="search"
                                    ariaLabel="Search"
                                    size="large"
                                    :onSubmitClicked="() => { onSubmit(); setQuery(textSearch) }"
                                    :onClearClicked="() => { textSearch = ''; setQuery(textSearch) }"
                                    :placeholder="searchPlaceholder"
                                />

                                <button v-if="meiliSearchQuery != searchString" class="submit-button">
                                    <Icon name="arrow-submit" @click="() => { onSubmit(); setQuery(textSearch) }" />
                                </button>
                            </form>

                            <div class="filter-toggler">
                                <Button
                                    @click="onFilterToggle"
                                    variant="secondary"
                                    iconPosition="left"
                                    :deactivated="!isFiltersToggled"
                                >
                                    <template #icon>
                                        <Icon name="filters" />
                                    </template>

                                    {{ $t('filters') }}
                                </Button>
                            </div>
                        </div>

                        <SearchFilters
                            class="index-filters"
                            v-if="hasFilters"
                            v-show="isFiltersToggled"
                            :title="title"
                            :hasSearch="hasSearch"
                            :pageLanguage="pageLanguage"
                            :enabledFilters="enabledFilters"
                            @onFilterChanged="setFilterAndPage($event, 1)"
                            :preselectedFilters="preselectedFilters"
                        />
                    </div>
                </div>

        <div class="name-filter-wrapper" v-if="nameFilter && pageLanguage === 'en'">
            <NameFilter @update:letter="nameFilterLetter = $event" />
        </div>

        <div class="results">
            <div class="results-inner">
                <Typography v-if="!hideResultCount" variant="label-small" class="result-count">
                    {{ total }} {{ $t('Results', 1, { locale: pageLanguage }) }}
                </Typography>
                    <slot name="results" :results="items" :nextPage="currentPage + 1"></slot>
            </div>
        </div>
        <div class="pagination">
            <Pagination
                @next="() => setPageAndScroll(currentPage + 1)"
                @previous="() => setPageAndScroll(currentPage - 1)"
                @page-select="(page: number) => setPageAndScroll(page)"
                :nextPage="currentPage + 1"
                :prevPage="currentPage - 1"
                :totalDocs="total"
                :totalPages="Math.ceil(total / hitsPerPage)"
            >
            </Pagination>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { SelectedFilters } from '@/composables/useFilterData';
const {
    indexName,
    filter,
    pageLanguage,
    title = '',
    hideEmptyResultsTitle = false,
    hideResultCount = false,
    hasSearch = false,
    attributesToSearch,
    hasFilters,
    enabledFilters,
    limit = 25,
    sortBy,
    sortDirection,
    preselectedFilters,
    disableSyndicationFilter = false,
    expertsPageFiltering,
    nameFilter = false,
    searchPlaceholder = '',
} = defineProps<{
    indexName: string;
    filter?: string;
    title?: string;
    hideResultCount?: boolean;
    pageLanguage: string;
    hideEmptyResultsTitle?: boolean;
    hasSearch?: boolean;
    attributesToSearch?: string[];
    hasFilters?: boolean;
    enabledFilters: { topics: boolean; regions: boolean; experts: boolean; years: boolean; contentType: boolean };
    limit?: number;
    sortBy?: string;
    sortDirection?: string;
    preselectedFilters?: string[];
    disableSyndicationFilter?: boolean;
    expertsPageFiltering: boolean;
    nameFilter?: boolean;
    searchPlaceholder?: string;
}>();

const { currentCenter } = useCenters();
const center = currentCenter.value?.id;

const tier = templateRef<HTMLDivElement>('tier');

const isFiltersToggled = ref(true);
const searchString = ref('');
const isSearched = ref(false);
const textSearch = ref('');
const searchFilter = ref<SelectedFilters>();
const nameFilterLetter = ref<string>('');

const filterSearchStr = computed(() => {
    let query: string[] = []

    if (expertsPageFiltering) {
        if (searchFilter.value?.topics?.length) {
            query.push(`featuredTopics.id IN [${searchFilter.value.topics.map((topic) => topic.id).join(',')}]`)
        }
        if (searchFilter.value?.regions?.length) {
            const regions: string[] = [];
            for (const region of searchFilter.value.regions) {
                const and: string[] = [];
                if (region.isChecked && !region.subItems?.length) {
                    and.push(`featuredRegions.id = ${region.id}`)
                }
                if (region.subItems?.length) {
                    and.push(`featuredRegions.id IN [${region.subItems.map((subItem) => subItem.id).join(',')}]`)
                }
                regions.push(`(${and.join(' AND ')})`)
            }
            query.push(`(${regions.join(' OR ')})`)
        }
        return query.join(' AND ')
    }
    
    if (searchFilter.value?.experts?.length) {
        query.push(`contributors.id IN [${searchFilter.value.experts.map((expert) => expert.id).join(',')}]`)
    }

    if (searchFilter.value?.topics?.length) {
        query.push(`syndications.centers.${center}.topics.id IN [${searchFilter.value.topics.map((topic) => topic.id).join(',')}]`)
    }

    if (searchFilter.value?.regions?.length) {
        const regions: string[] = [];
        for (const region of searchFilter.value.regions) {
            const and: string[] = [];
            if (region.isChecked && !region.subItems?.length) {
                and.push(`syndications.centers.${center}.regions.id = ${region.id}`)
            }
            if (region.subItems?.length) {
                and.push(`syndications.centers.${center}.regions.id IN [${region.subItems.map((subItem) => subItem.id).join(',')}]`)
            }
            regions.push(`(${and.join(' AND ')})`)
        }
        query.push(`(${regions.join(' OR ')})`)
    }

    if (searchFilter.value?.years?.length) {
        query.push(`year IN [${searchFilter.value.years.map(year => year.id).join(',')}]`)   
    }

    if (searchFilter.value?.contentType.length) {
        query.push('collection IN [' + searchFilter.value.contentType.map((type) => `'${type.id}'`).join(',') + ']')
    }

    return query.join(' AND ')
});

const allFilters = computed(() => {
    return `${disableSyndicationFilter ? '' : `syndications.centers.${center}.syndicated = TRUE AND `}status = published ${filter ? ` AND (${filter})` : ''}${filterSearchStr.value ? ` AND (${filterSearchStr.value})` : ''} ${nameFilterLetter.value ? ` AND ((lastName STARTS WITH ${nameFilterLetter.value}) OR (lastName STARTS WITH ${nameFilterLetter.value.toLowerCase()}))` : ''}`
});

function onFilterToggle() {
    isFiltersToggled.value = !isFiltersToggled.value;
}

function onSubmit() {
    textSearch.value = searchString.value;
    isSearched.value = true;
}

const showClearIcon = computed(() => {
    return !!isSearched.value && !!searchString.value;
});

const {
    hits: items,
    total,
    currentPage,
    setPage,
    hitsPerPage,
    setFilter,
    setQuery,
    searchQuery: meiliSearchQuery,
} = useAsyncMeiliSearch(indexName, {
    hitsPerPage: limit,
    page: 1,
    filter: allFilters.value,
    sort: `${sortBy || 'sort'}:${sortDirection || 'desc'}`,
    query: searchString.value || undefined,
    attributesToSearch: attributesToSearch,
})

watch(allFilters, () => {
    setFilter(allFilters.value)
})

const setPageAndScroll = (page: number) => {
    setPage(page);
    tier.value.scrollIntoView();
}

const setFilterAndPage = (filter: SelectedFilters, page: number) => {
    searchFilter.value = filter;
    setPage(page);
}

</script>

<style lang="scss" scoped>
.content-index-tier {
    padding-bottom: 0.9rem;
    border-bottom: 1px solid palette(divider);
    background-color: color(blanc);
}

.heading {
    padding-top: vertical-space(6);
    background-color: color(grey, light);
    @include hide-on(phone);
}

.heading-wrapper {
    @include content-section;
}

.title {
    padding-top: vertical-space(1);
    padding-bottom: vertical-space(1);
    @include content-area;
}

.panel {
    @include content-section;

    @include media-query(phone) {
        @include z-index(header);
        position: sticky;
        top: -1px;
        background-color: color(blanc);

        &.is-pinned {
            box-shadow: 0 0.2rem 1rem 0 #0000001a;
        }
    }
}

.panel-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    @include content-area;

    @include media-query(phone-mw) {
        display: block;
    }
}

.search {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 2.8rem;
    flex-grow: 1;

    @include media-query(phone-mw) {
        margin-bottom: 2.4rem;
    }
}

.search-input {
    flex: 1 1 auto;
    position: relative;
}

.submit-button {
    position: absolute;
    inset-inline-end: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    fill: color(blue);
    width: 2.5rem;
}

.index-filters {
    padding: 0.9rem 0;

    @include media-query(phone) {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
}

.filter-toggler {
    flex: 0 0 auto;
    display: none;

    @include media-query(phone-mw) {
        display: block;
    }
}

.results {
    @include content-section;
    margin-block: 5rem;
}

.results-inner {
    padding-top: 5rem;
    @include content-area;
}

.result-count {
    opacity: 0.65;
}

.desktop-results {
    display: none;

    @include media-query(phone-mw) {
        display: block;
    }
}

.mobile-results {
    @include media-query(phone-mw) {
        display: none;
    }
}

.name-filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include content-area;
}
</style>
